export default {
    data() {
        return {
            statuses: [
                { text: 'All Statuses', value: '' },
                { text: 'Draft', value: 'Draft' },
                { text: 'Sent for Enrollment', value: 'Sent for Enrollment' },
                { text: 'Active', value: 'Active' },
                { text: 'Received, Processing', value: 'Received, Processing' },
                { text: 'Inactive', value: 'Inactive' }
            ]
        };
    },
    methods: {
        getStatusClass(item) {
            switch (item.Status) {
                case 'Active':
                    return 'text-success';
                case 'Draft':
                    return 'text-primary';
                case 'Inactive':
                    return 'text-muted';
                case 'Received, Processing':
                    return 'text-brand-dark';
                case 'Rejected, Needs Review':
                    return 'text-danger';
            }
        },
    }
}