<template>
    <div>
        <b-breadcrumb :items="[{text: 'Enrollee List', active: true}]"></b-breadcrumb>
        <b-row align-h="between">
            <b-col cols="12" md="6" lg="5" xl="5" class="text-center text-md-left">
                <b-row>
                    <b-col cols="7">
                        <b-form @submit.prevent="search">
                            <b-input-group>
                                <b-form-input v-model="searchText" placeholder="Search" :disabled="loading"></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="outline-secondary" @click="search" :disabled="loading"><i class="fa fa-search"></i></b-button>
                                    <b-button v-if="filter" variant="danger" @click="searchText = null; filter = null" :disabled="loading"><i class="fa fa-ban"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form>
                    </b-col>
                    <b-col>
                        <b-form-select v-model="status" :options="statuses"></b-form-select>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" md="6" lg="7" xl="7" class="text-center text-md-right">
                <b-row class="justify-content-center justify-content-md-end">
                    <transition name="fade">
                        <b-col cols="12" md="auto" class="text-center text-md-right pt-2 text-muted font-weight-bold" v-if="rowsFound">
                            {{rowsFound | commify}} Total
                        </b-col>
                    </transition>
                    <b-col cols="12" md="auto" class="mt-2 mt-md-0">
                        <b-row>
                            <b-col class="d-none d-lg-block">
                                <b-pagination v-model="current_page" :total-rows="rowsFound" :per-page="per_page" align="fill" :disabled="loading" first-number last-number>
                                    <template v-slot:first-text>
                                        <i class="fa fa-angle-double-left"></i>
                                    </template>
                                    <template v-slot:prev-text>
                                        <i class="fa fa-angle-left"></i>
                                    </template>
                                    <template v-slot:next-text>
                                        <i class="fa fa-angle-right"></i>
                                    </template>
                                    <template v-slot:last-text>
                                        <i class="fa fa-angle-double-right"></i>
                                    </template>
                                </b-pagination>
                            </b-col>
                            <b-col size="auto">
                                <b-form @submit.prevent="currentPage = $refs.jumpToPage.$el.value;" class="d-inline">
                                    <b-input-group>
                                        <b-form-input type="number" title="Page Number" :value="current_page" :disabled="loading" ref="jumpToPage" style="max-width: 80px;" />
                                        <b-input-group-append>
                                            <b-button type="submit" title="Jump to Page" variant="outline-secondary">
                                                Go
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <transition name="fade" mode="out-in">
            <b-progress variant="secondary" key="loader" v-if="loading" :value="100" :max="100" animated></b-progress>
            <b-list-group v-else-if="rowsFound > 0">
                <b-list-group-item v-for="item in rows" :key="item.CliId" href="javascript: void(0);" @click="navigateTo(item)">
                    <b-row>
                        <b-col cols="12" md="6" order-md="1" order-lg="1" lg="3" class="text-primary">
                            {{item.FirstName}} {{item.LastName}}
                        </b-col>
                        <b-col cols="12" md="6" order-md="3" order-lg="2" lg="3">
                            {{item.City}}<span v-if="item.State && item.Zip">, {{item.State}} {{item.Zip}}</span>
                        </b-col>
                        <b-col cols="12" md="6" order-md="2" order-lg="3" lg="3">
                            {{item.EnrollmentTypes}}
                        </b-col>
                        <b-col cols="12" md="6" order-md="4" order-lg="4" lg="3" :class="getStatusClass(item)">
                            {{item.Status}}
                        </b-col>
                    </b-row>

                </b-list-group-item>
            </b-list-group>
            <div v-else class="border p-4 text-muted text-center">
                no records found
            </div>
        </transition>
        <transition name="fade" mode="out-in">
            <b-row v-if="rowsFound > 0" class="mt-3 justify-content-center justify-content-md-end">
                <b-col cols="12" md="auto" class="mt-2 mt-md-0">
                    <b-row>
                        <b-col class="d-none d-lg-block">
                            <b-pagination v-model="current_page" :total-rows="rowsFound" :per-page="per_page" align="fill" :disabled="loading" first-number last-number>
                                <template v-slot:first-text>
                                    <i class="fa fa-angle-double-left"></i>
                                </template>
                                <template v-slot:prev-text>
                                    <i class="fa fa-angle-left"></i>
                                </template>
                                <template v-slot:next-text>
                                    <i class="fa fa-angle-right"></i>
                                </template>
                                <template v-slot:last-text>
                                    <i class="fa fa-angle-double-right"></i>
                                </template>
                            </b-pagination>
                        </b-col>
                        <b-col size="auto">
                            <b-form @submit.prevent="currentPage = $refs.jumpToPage.$el.value;" class="d-inline">
                                <b-input-group>
                                    <b-form-input type="number" title="Page Number" :value="current_page" :disabled="loading" ref="jumpToPage" style="max-width: 80px;" />
                                    <b-input-group-append>
                                        <b-button type="submit" title="Jump to Page" variant="outline-secondary">
                                            Go
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </transition>
    </div>
</template>
<script lang="ts">
    import ClientBase from './mixins/ClientBase';
    export default {
        mixins: [ClientBase],
        data() {
            return {
                loading: true,
                rows: [],
                current_page: 1,
                per_page: 50,
                sort_by: 'LastName',
                sort_direction: 'ASC',
                status: '',
                searchText: null,
                filter: null,
                rowsFound: 0,
            };
        },
        async mounted() {
            if (this.$route.query.status) this.status = this.$route.query.status;
            if (this.$route.query.q) this.filter = this.searchText = this.$route.query.q;
            await this.get();
            this.loading = false;
        },
        methods: {
            async get() {
                const response = await this.$remote.request('Client', 'List', { current_page: this.current_page, per_page: this.per_page, sort_by: this.sort_by, sort_direction: this.sort_direction, status: this.status, search: this.filter });
                this.rows = response.data;
                this.rowsFound = response.total;
            },
            navigateTo(item) {
                this.$router.push({ name: 'ClientView', params: { id: item.CliId } });
            },
            async reload() {
                this.loading = true;
                this.rows = [];
                await this.get();
                this.loading = false;
            },
            search() {
                this.filter = this.searchText;
            }
        },
        watch: {
            filter(val, oldVal) {
                if (val != oldVal) this.reload();
            },
            status(val, oldVal) {
                if (val != oldVal) this.reload();
            }
        }
    }
</script>